import {
    axios
} from '@/utils/request'

//农村居民和移民人均收入可支配收入情况

// 获取列表数据
export function _DisposableIncomePage(params) {
    return axios({
        url: '/DisposableIncome/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _DisposableIncomeAdd(params) {
    return axios({
        url: '/DisposableIncome/Add',
        method: 'post',
        data: params,
    })
}

// 编辑
export function _DisposableIncomeEdit(params) {
    return axios({
        url: '/DisposableIncome/Edit',
        method: 'post',
        data: params,
    })
}


// 删除
export function _DisposableIncomeDelete(params) {
    return axios({
        url: '/DisposableIncome/Delete',
        method: 'post',
        data: params,
    })
}



//详情
export function _DisposableIncomeInfo(params) {
    return axios({
        url: '/DisposableIncome/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _DisposableIncomeImport(params) {
    return axios({
        url: '/DisposableIncome/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _DisposableIncomeExport(params) {
    return axios({
        url: '/DisposableIncome/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





