import {
    axios
} from '@/utils/request'

// 信访维稳

// 获取列表数据
export function _AdvocacyPage(params) {
    return axios({
        url: '/Policy/AdvocacyPage',
        method: 'get',
        params,
    })
}

// 新增
export function _AdvocacyAdd(params) {
    return axios({
        url: '/Policy/AdvocacyAdd',
        method: 'post',
        data: params,
    })
}

// 编辑
export function _AdvocacyEdit(params) {
    return axios({
        url: '/Policy/AdvocacyEdit',
        method: 'post',
        data: params,
    })
}


// 删除
export function _AdvocacyDelete(params) {
    return axios({
        url: '/Policy/AdvocacyDelete',
        method: 'post',
        data: params,
    })
}


//详情
export function _AdvocacyInfo(params) {
    return axios({
        url: '/Policy/AdvocacyInfo',
        method: 'get',
        params,
    })
}


// excel导入
export function _ImportAdvocacy(params) {
    return axios({
        url: '/Policy/ImportAdvocacy',
        method: 'get',
        params,
    })
}


// excel导出
export function _ExportAdvocacy(params) {
    return axios({
        url: '/Policy/ExportAdvocacy',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





