
import {
    axios
} from '@/utils/request'

// 获取数据 图表
export function _EngineeringStatisticData(params) {
    return axios({
        url: '/engineering/EngineeringStatisticData',
        method: 'get',
        params,
    })
}

// 获取数据 列表
export function _EngineeringPage(params) {
    return axios({
        url: '/engineering/EngineeringPage',
        method: 'get',
        params,
    })
}


// 导入 列表
export function _ImportProject(params) {
    return axios({
        url: '/engineering/ImportProject',
        method: 'get',
        params,
    })
}

// 导入 统计表
export function _ImportProjectStatistic(params) {
    return axios({
        url: '/engineering/ImportProjectStatistic',
        method: 'get',
        params,
    })
}

// 导出 统计表
export function _EngineeringStatisticExport(params) {
    return axios({
        url: '/engineering/EngineeringStatisticExport',
        responseType: 'blob',
        method: 'get',
        params,
    })
}

// 导出 列表
export function _EngineeringExport(params) {
    return axios({
        url: '/engineering/EngineeringExport',
        responseType: 'blob',
        method: 'get',
        params,
    })
}



