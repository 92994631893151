import {
    axios
} from '@/utils/request'

//样本户移民抽样调查情况

// 获取列表数据
export function _SampleHouseholdPage(params) {
    return axios({
        url: '/SampleHousehold/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _SampleHouseholdAdd(params) {
    return axios({
        url: '/SampleHousehold/Add',
        method: 'post',
        data: params,
    })
}

// 编辑
export function _SampleHouseholdEdit(params) {
    return axios({
        url: '/SampleHousehold/Edit',
        method: 'post',
        data: params,
    })
}


// 删除
export function _SampleHouseholdDelete(params) {
    return axios({
        url: '/SampleHousehold/Delete',
        method: 'post',
        data: params,
    })
}



//详情
export function _SampleHouseholdInfo(params) {
    return axios({
        url: '/SampleHousehold/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _SampleHouseholdImport(params) {
    return axios({
        url: '/SampleHousehold/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _SampleHouseholdExport(params) {
    return axios({
        url: '/SampleHousehold/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





