import {
    axios
} from '@/utils/request'
// 政策实施基本情况
// 获取列表数据
export function _ImplementationPage(params) {
    return axios({
        url: '/Policy/ImplementationPage',
        method: 'get',
        params,
    })
}

// 新增
export function _ImplementationAdd(params) {
    return axios({
        url: '/Policy/ImplementationAdd',
        method: 'post',
        data: params,
    })
}

// 编辑
export function _ImplementationEdit(params) {
    return axios({
        url: '/Policy/ImplementationEdit',
        method: 'post',
        data: params,
    })
}

// 删除
export function _ImplementationDelete(params) {
    return axios({
        url: '/Policy/ImplementationDelete',
        method: 'post',
        data: params,
    })
}


// 详情
export function _ImplementationInfo(params) {
    return axios({
        url: '/Policy/ImplementationInfo',
        method: 'get',
        params,
    })
}



// excel导入
export function _ImportImplementation(params) {
    return axios({
        url: '/Policy/ImportImplementation',
        method: 'get',
        params,
    })
}


// excel导出
export function _ExportImplementation(params) {
    return axios({
        url: '/Policy/ExportImplementation',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





