import {
    axios
} from '@/utils/request'

//移民群众生产生活条件变化情况

// 获取列表数据
export function _DemographicChangePage(params) {
    return axios({
        url: '/DemographicChange/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _DemographicChangeAdd(params) {
    return axios({
        url: '/DemographicChange/Add',
        method: 'post',
        data: params,
    })
}

// 编辑
export function _DemographicChangeEdit(params) {
    return axios({
        url: '/DemographicChange/Edit',
        method: 'post',
        data: params,
    })
}


// 删除
export function _DemographicChangeDelete(params) {
    return axios({
        url: '/DemographicChange/Delete',
        method: 'post',
        data: params,
    })
}



//详情
export function _DemographicChangeInfo(params) {
    return axios({
        url: '/DemographicChange/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _DemographicChangeImport(params) {
    return axios({
        url: '/DemographicChange/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _DemographicChangeExport(params) {
    return axios({
        url: '/DemographicChange/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





