import {
    axios
} from '@/utils/request'

// 直补资金的发放情况

// 获取列表数据
export function _DirectFundsPage(params) {
    return axios({
        url: '/DirectFunds/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _DirectFundsAdd(params) {
    return axios({
        url: '/DirectFunds/Add',
        method: 'post',
        data: params,
    })
}

// 编辑
export function _DirectFundsEdit(params) {
    return axios({
        url: '/DirectFunds/Edit',
        method: 'post',
        data: params,
    })
}

// 删除
export function _DirectFundsDelete(params) {
    return axios({
        url: '/DirectFunds/Delete',
        method: 'post',
        data: params,
    })
}




//详情
export function _DirectFundsInfo(params) {
    return axios({
        url: '/DirectFunds/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _DirectFundsImport(params) {
    return axios({
        url: '/DirectFunds/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _DirectFundsExport(params) {
    return axios({
        url: '/DirectFunds/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





