import {
    axios
} from '@/utils/request'

//监测评估工作人力和物力投入情况

// 获取列表数据
export function _InformationPage(params) {
    return axios({
        url: '/Information/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _InformationAdd(params) {
    return axios({
        url: '/Information/Add',
        method: 'post',
        data: params,
    })
}

// 编辑
export function _InformationEdit(params) {
    return axios({
        url: '/Information/Edit',
        method: 'post',
        data: params,
    })
}


// 删除
export function _InformationDelete(params) {
    return axios({
        url: '/Information/Delete',
        method: 'post',
        data: params,
    })
}



//详情
export function _InformationInfo(params) {
    return axios({
        url: '/Information/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _InformationImport(params) {
    return axios({
        url: '/Information/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _InformationExport(params) {
    return axios({
        url: '/Information/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





