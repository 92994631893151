import {
    axios
} from '@/utils/request'


// 获取数据(人口特征和经济发展水平主要情况)
export function _PopulationDataEconomic(params) {
    return axios({
        url: '/Economic/PopulationData',
        method: 'get',
        params,
    })
}

//获取列表数据(经济社会发展水平主要情况统计)
export function _EconomicPageEconomic(params) {
    return axios({
        url: '/Economic/EconomicPage',
        method: 'get',
        params,
    })
}


// 导出表格  统计表
export function _PopulationExport(params) {
    return axios({
        url: '/Economic/PopulationExport',
        method: 'get',
        params,
        responseType: 'blob',
    })
}


// 导出表格  列表
export function _EconomicExport(params) {
    return axios({
        url: '/Economic/EconomicExport',
        method: 'get',
        params,
        responseType: 'blob',
    })
}

// 导入 人口经济
export function _ImportPopulation(params) {
    return axios({
        url: '/Economic/ImportPopulation',
        method: 'get',
        params,
        responseType: 'blob',
    })
}

//导入  经济社会发展水平主要情况
export function _ImportDevelopment(params) {
    return axios({
        url: '/Economic/ImportDevelopment',
        method: 'get',
        params,
    })
}



