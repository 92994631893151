import {
    axios
} from '@/utils/request'

// 中央水库移民扶持基金支出进度

// 获取列表数据
export function _ProgressReportPage(params) {
    return axios({
        url: '/ProgressReport/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _ProgressReportAdd(params) {
    return axios({
        url: '/ProgressReport/Add',
        method: 'post',
        data: params,
    })
}

// 删除
export function _ProgressReportDelete(params) {
    return axios({
        url: '/ProgressReport/Delete',
        method: 'post',
        data: params,
    })
}


// 编辑
export function _ProgressReportEdit(params) {
    return axios({
        url: '/ProgressReport/Edit',
        method: 'post',
        data: params,
    })
}



//详情
export function _ProgressReportInfo(params) {
    return axios({
        url: '/ProgressReport/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _ProgressReportImport(params) {
    return axios({
        url: '/ProgressReport/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _ProgressReportExport(params) {
    return axios({
        url: '/ProgressReport/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





