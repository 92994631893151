<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="24%"
      :before-close="btnClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div class="import_header">
        <div class="title">导入</div>
        <div @click="btnClose()" class="close">关闭</div>
      </div>
      <div class="import_content">
        <div class="import_item">
          <div class="title">下载导入模板，根据模板完善内容</div>
          <el-button size="small" @click="downloadTemplate"> <i class="el-icon-download"></i>下载模板</el-button>
        </div>

        <div class="import_item">
          <div class="select_year">
            <div v-if="filetype !== '1-1-1'" class="select_box1">
              <div class="text">请先选择导入年度：</div>
              <div class="box">
                <div :class="['box_arrow', notAllow1 ? 'box_arrow1' : '']" @click="handlePrev">
                  <i class="el-icon-arrow-left" style="color:#4E5969;"></i>
                </div>
                <div class="my_year">{{ year }}年度</div>
                <div :class="['box_arrow', notAllow2 ? 'box_arrow2' : '']" @click="handleNext">
                  <i class="el-icon-arrow-right" style="color:#4E5969;"></i>
                </div>
              </div>
            </div>

            <div v-if="['1-1-1', '2-1', '1-7-3', '1-2-2'].includes(filetype)" class="select_box2">
              <div class="text">请先选择行政区域：</div>
              <div class="box">
                <el-select
                  filterable
                  v-model="OrgId"
                  clearable
                  @change="handleChangeOrgId"
                  size="mini"
                  placeholder="请选择行政区域"
                >
                  <el-option v-for="item in treeData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="title">上传完善好的内容</div>
          <el-upload
            class="upload_demo"
            ref="upload"
            action=""
            :http-request="customRequest"
            :before-upload="beforeUpload"
            :file-list="fileList"
            :limit="1"
            :accept="'.xlsx'"
          >
            <!-- :accept="'.xls,.xlsx'" -->

            <template #default>
              <el-button size="small"> <i class="el-icon-upload"></i>上传附件</el-button>
            </template>

            <template #file="{ file }">
              <div class="file_item">
                <div class="file_info">
                  <div class="file_name">{{ file.name }}</div>
                  <div class="file_actions">
                    <el-link type="danger" size="small" @click.prevent.stop="handleRemove(file)">删除</el-link>
                  </div>
                </div>
              </div>
            </template>
          </el-upload>

          <div class="import_opration">
            <el-button type="primary" size="small" plain @click="btnClose()">取消</el-button>
            <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { sysFileInfoUpload, sysFileInfoDownload } from '@/api/modular/system/fileManage'
import {
  _ImportDevelopment,
  _ImportPopulation
} from '@/api/modular/postImmigrationService/HomePage/socialDevelopment.js'

import {
  _ImportProjectStatistic,
  _ImportProject
} from '@/api/modular/postImmigrationService/HomePage/hydropowerProjects.js'

import { _ImportImplementation } from '@/api/modular/postImmigrationService/policy/basicSituation.js'
import { _ImportAdvocacy } from '@/api/modular/postImmigrationService/policy/policyPreaching.js'
import { _PlanningSituationImport } from '@/api/modular/postImmigrationService/policy/mediumSized.js'
import { _DirectFundsImport } from '@/api/modular/postImmigrationService/policy/subsidyFunds.js'
import { _ProjectFieldworkImport } from '@/api/modular/postImmigrationService/policy/fieldInvestigation.js'
import { _PorjectTypicalSpotChecksImport } from '@/api/modular/postImmigrationService/policy/typicalSpotCheck.js'
import { _BudgetExpenditureImport } from '@/api/modular/postImmigrationService/monitorAndTheUse/useOfFunds.js'
import { _DisposableIncomeImport } from '@/api/modular/postImmigrationService/monitorAndEvaluate/disposableIncome.js'
import { _ProductionLivingImport } from '@/api/modular/postImmigrationService/monitorAndEvaluate/changeSituation.js'
import { _SampleHouseholdStatisticImport } from '@/api/modular/postImmigrationService/monitorAndEvaluate/ImmigrationStatistics.js'
import { _SampleHouseholdImport } from '@/api/modular/postImmigrationService/monitorAndEvaluate/sampleHouseholds.js'
import { _InformationImport } from '@/api/modular/postImmigrationService/workingCondition/InputSituation.js'
import { _InvestmentImport } from '@/api/modular/postImmigrationService/workingCondition/verificationOfData.js'
import { _ProgressReportImport } from '@/api/modular/postImmigrationService/expenditureProgress/supportFund.js'
import { _ProjectInventoryImport } from '@/api/modular/postImmigrationService/policy/inventoryList.js'
import { _DemographicChangeImport } from '@/api/modular/postImmigrationService/monitorAndEvaluate/naturalChange.js'
import { _ApprovedImmigrationImport } from '@/api/modular/postImmigrationService/monitorAndEvaluate/newApproval.js'
import { _QuestionImport } from '@/api/modular/postImmigrationService/workingCondition/listOfQuestions.js'

import { _getLevelRegion } from '@/api/keepHome'

import templateTree from '@/components/templateView/templateTree.vue'

export default {
  name: 'HcYmhfImportExcel',

  data() {
    return {
      dialogVisible: false,
      fileList: [],
      upLoadFiles: [],
      year: null,
      years: [],
      filetype: '',
      notAllow1: false,
      notAllow2: false,
      treeData: [],
      dck: [],
      OrgId: null,
      OrgName: '',
      OrgPids: ''
    }
  },

  mounted() {},
  components: {
    templateTree
  },

  methods: {
    getLevelRegion(val) {
      let params = {
        startLevel: 2,
        endLevel: 2,
        inclusionLevel: false
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.treeData = res.data
          let provincialCenterId = this.treeData[0].id //默认展开第一个节点
          this.dck = [provincialCenterId]
          this.downloadObj = this.treeData[0]
        }
      })
    },

    handleChangeOrgId(val) {
      let obj = this.treeData.find(ele => ele.id == val)

      this.OrgId = obj?.id
      this.OrgName = obj?.name
      this.OrgPids = obj?.pids
    },
    refreshNode(obj, IsLevel) {
      // OrgId ,OrgName ,OrgPids
      this.OrgId = obj.id
      this.OrgName = obj.name
      this.OrgPids = obj.pids
    },

    view(year, years, filetype) {
      this.filetype = filetype

      switch (this.filetype) {
        case '1-1-2':
        case '1-2-1':
        // case '1-2-2':
        case '1-4-1':
        case '2-3':
        case '3-1':
        case '2-2':
        case '1-4-2':
        case '1-4-4':
        case '1-4-5':
        case '1-7-1':
        case '1-7-2':
        case '1-7-4':
        case '1-3-1':
        case '1-3-2':
        case '2-4':
        case '1-5-2':
        case '2-4-2':
        case '2-3-2':
        case '1-7-5':
          this.year = year
          this.years = years
          break

        case '2-1': //
        case '1-7-3': //监评资金->样本户移民情况统计
        case '1-1-1': //首页1,统计图
        case '1-2-2': //首页2,统计图
          this.year = year
          this.years = years
          this.getLevelRegion()
          break
        default:
          break
      }
      this.dialogVisible = true
    },
    btnClose() {
      ;(this.OrgId = null), (this.OrgName = ''), (this.OrgPids = '')
      this.fileList = []
      this.upLoadFiles = []
      this.dialogVisible = false
    },
    handlePrev() {
      let currentYear = this.year
      let currentYearIndex = this.years.findIndex(function(item) {
        return item.value === currentYear
      })

      if (currentYearIndex > 0) {
        this.notAllow1 = false
        this.notAllow2 = true
        currentYearIndex--
      } else {
        currentYearIndex = 0
        this.notAllow1 = true
        this.notAllow2 = false
      }
      this.year = this.years[currentYearIndex]?.value
    },
    handleNext() {
      let currentYear = this.year
      let currentYearIndex = this.years.findIndex(function(item) {
        return item.value === currentYear
      })
      if (currentYearIndex < this.years.length - 1) {
        this.notAllow2 = false
        this.notAllow1 = true
        currentYearIndex++
      } else {
        currentYearIndex = this.years.length - 1
        this.notAllow2 = true
        this.notAllow1 = false
      }
      this.year = this.years[currentYearIndex]?.value
    },
    submitForm() {
      switch (this.filetype) {
        case '1-1-1':
          if (this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0]
            }
            _ImportPopulation(params).then(res => {
              if (res.status == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else {
            this.$message.error('请先选择导入附件')
          }
          break
        case '1-1-2':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _ImportDevelopment(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '1-2-1':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _ImportProject(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '1-2-2':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _ImportProjectStatistic(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '1-4-1':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _ImportImplementation(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '2-3':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _ImportAdvocacy(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '3-1':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _PlanningSituationImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '2-2':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _ImportAdvocacy(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '1-4-2':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _DirectFundsImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '1-4-4':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _PorjectTypicalSpotChecksImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '1-4-5':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _ProjectFieldworkImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '2-1':
          if (this.year && this.upLoadFiles.length && this.OrgId) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year,
              OrgId: this.OrgId,
              OrgName: this.OrgName,
              OrgPids: this.OrgPids
            }
            _BudgetExpenditureImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.OrgId) {
            this.$message.error('请先选择行政区域')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '1-7-1':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _DisposableIncomeImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '1-7-2':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _ProductionLivingImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '1-7-3':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year,
              OrgId: this.OrgId,
              OrgName: this.OrgName,
              OrgPids: this.OrgPids
            }
            _SampleHouseholdStatisticImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '1-7-4':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _SampleHouseholdImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '1-3-1':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _InformationImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '1-3-2':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _InvestmentImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break
        case '2-4':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _ProgressReportImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break

        case '1-5-2':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _ProjectInventoryImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break

        case '2-4-2':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _DemographicChangeImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break

        case '2-3-2':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _ApprovedImmigrationImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break

        case '1-7-5':
          if (this.year && this.upLoadFiles.length) {
            let ids = this.upLoadFiles.map(ele => ele.id)
            let params = {
              id: ids[0],
              year: this.year
            }
            _QuestionImport(params).then(res => {
              if (res.code == 200) {
                this.$message.success('导入成功')
                this.$emit('refreshTable')
                this.btnClose()
              } else {
                this.$message.error('导入失败')
                this.btnClose()
              }
            })
          } else if (!this.year) {
            this.$message.error('请先选择导入年度')
          } else if (!this.upLoadFiles.length) {
            this.$message.error('请先选择导入附件')
          }
          break

        default:
          break
      }
    },
    beforeUpload(file) {
      const isExcel = /\.xlsx$/.test(file.name)

      if (!isExcel) {
        this.$message.error('请上传Excel文件（.xlsx 格式）')
        return false // 阻止非Excel文件的上传
      }

      // 如果是Excel文件，则可以进行进一步的处理或直接返回true允许上传
      return true
    },
    downloadTemplate() {
      var href
      switch (this.filetype) {
        case '1-1-1':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表1-1-1人口特征和经济发展水平主要情况统计.xlsx'
          break
        case '1-1-2':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表1-1-2经济社会发展水平主要情况统计.xlsx'
          break
        case '1-2-1':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表1-2-1大中型水利水电工程主要特征.xlsx'
          break
        case '1-2-2':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表1-2-2已建在建大中型水利水电工程基本情况统计.xlsx'
          break
        case '1-4-1':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表1-4-1移民后扶政策实施基本情况统计.xlsx'
          break
        case '2-3':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/2表3大中型水库移民信访维稳及政策宣讲情况.xlsx'
          break
        case '3-1':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表3-1大中型水库后扶相关规划编审情况统计.xlsx'
          break
        case '2-2':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/2表2后期扶持项目实施情况统计台账.xlsx'
          break
        case '1-4-2':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表4-2大中型水库移民后期扶持直补资金发放情况统计表.xlsx'
          break
        case '1-4-4':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表4-4移民后扶规划实施情况典型项目抽查汇总表.xlsx'
          break
        case '1-4-5':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表4-5后期扶持计划项目实施情况全面核查汇总.xlsx'
          break
        case '2-1':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/2表1大中型水库移民后期扶持资金使用情况.xlsx'
          break
        case '1-7-1':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表7-1农村居民和移民人均可支配收入情况分析汇总.xlsx'
          break
        case '1-7-2':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表7-2大中型水库移民生产生活状况统计表.xlsx'
          break
        case '1-7-3':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表1-7-3样本户移民情况统计表.xlsx'
          break
        case '1-7-4':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表1-7-4样本户情况调查表.xlsx'
          break
        case '1-3-1':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表1-3-1四川省监测评估工作人力和物力投入情况统计.xlsx'
          break
        case '1-3-2':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表1-3-2监测评估工作样本调查及核查资料情况统计.xlsx'
          break
        case '2-4':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/2表4中央水库移民扶持基金支出进度报表.xlsx'
          break
        case '1-5-2':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表5-2大中型水库移民后期扶持资金项目清查表.xlsx'
          break
        case '2-4-2':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表2-4-2大中型水库移民后期扶持人口自然变化及处理情况.xlsx'
          break
        case '2-3-2':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表2-3-2市（州）年度大中型水库新核定移民情况监测.xlsx'
          break
        case '1-7-5':
          href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表7-5问题收集情况统计.xlsx'
          break
        default:
          break
      }

      var downloadElement = document.createElement('a')
      //   var href = 'https://ymhf.schaice.com/file/ExcelTemplate/1表1-1-2经济社会发展水平主要情况统计.xlsx' // 创建下载的链接
      downloadElement.style.display = 'none'
      downloadElement.href = href
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
      window.URL.revokeObjectURL(href)
    },
    handleRemove(file) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const index = this.fileList.indexOf(file)
          if (index !== -1) {
            this.fileList.splice(index, 1)
          }
          this.upLoadFiles = this.upLoadFiles.filter(item => item.id !== file.id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    customRequest(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      const index = data.file.name.lastIndexOf('.')
      const extension = data.file.name.substring(index + 1)

      //   this.setFileType(extension)
      sysFileInfoUpload(formData).then(res => {
        if (res.code == 200) {
          let imgsObj = {
            id: res.data,
            name: data.file.name
          }

          this.fileList.splice(this.fileList.length, 0, imgsObj)
          this.upLoadFiles.splice(this.upLoadFiles.length, 0, imgsObj)
          this.$message.success('上传成功')
          // this.$refs.table.refresh()
        } else {
          this.$message.error('上传失败：' + res.message)
        }
      })
    },
    handleClick(item) {
      sysFileInfoDownload({ id: item.id })
        .then(res => {
          this.handleDownloadFiles(res)
        })
        .catch(err => {
          this.$message.error('下载错误：获取文件流错误')
        })
    },
    handleDownloadFiles(res) {
      var contentDisposition = res.headers['content-disposition']
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(contentDisposition)
      const filename1 = matches[1].replace(/['"]/g, '')
      const filename = filename1.replace(/^_+/g, '')
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(res.data) // 创建下载的链接
      var reg = /^["](.*)["]$/g
      downloadElement.style.display = 'none'
      downloadElement.href = href
      downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
      window.URL.revokeObjectURL(href)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
.upload_demo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
// 弹框样式
::v-deep .el-dialog__header {
  padding: 0 0;
}

::v-deep .el-dialog__body {
  padding: 10px 0;

  font-family: PingFangSC-Bold-Bold;

  .import_header {
    height: 0.6rem;
    padding: 0 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dcdfe6;

    & > .title {
      font-size: 0.25rem;
      font-weight: 600;
      line-height: 0.35rem;
      letter-spacing: 0px;
      color: #1d2129;
    }

    & > .close {
      font-size: 0.1875rem;
      font-weight: normal;
      line-height: 0.275rem;
      text-align: right;
      align-items: center;
      letter-spacing: 0px;
      color: #165dff;
      cursor: pointer;
    }
  }

  .import_content {
    padding: 0 0.2rem;
    margin: 0.15rem auto;
    max-height: 600px;
    overflow: auto;

    & > .import_item {
      width: 100%;
      padding: 0.1875rem;
      background: #f8f9fd;
      margin-bottom: 0.125rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & > .title {
        margin-bottom: 0.125rem;
      }

      & > .select_year {
        width: 100%;
        padding: 0.1875rem;

        margin-bottom: 0.125rem;

        & > .select_box1 {
          display: flex;
          align-items: center;
          margin-bottom: 0.125rem;
          & > .text {
            font-size: 12px;
            font-weight: normal;
            line-height: 28px;
            letter-spacing: 0px;
            color: #1d2129;
          }

          & > .box {
            display: flex;
            align-items: center;
            & > .box_arrow {
              background: #f7f8f7;
              padding: 2px 5px;
              border-radius: 4px;
              box-sizing: border-box;
              border: 1px solid #cccccc;
              cursor: pointer;
            }

            & > .box_arrow1 {
              cursor: not-allowed !important;
            }
            & > .box_arrow2 {
              cursor: not-allowed !important;
            }

            & > .my_year {
              font-family: PingFang SC;
              font-size: 16px;
              font-weight: bold;
              line-height: 28px;
              letter-spacing: 0px;
              text-decoration: underline;
              color: #3f8cff;
              margin: 0 8px;
            }
          }
        }

        & > .select_box2 {
          display: flex;
          align-items: center;
          margin-bottom: 0.125rem;
          & > .text {
            font-size: 12px;
            font-weight: normal;
            line-height: 28px;
            letter-spacing: 0px;
            color: #1d2129;
          }

          & > .box {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .import_opration {
    padding: 10px;
    align-self: flex-end;
  }
}

::v-deep .el-divider--horizontal {
  margin: 0.15rem 0 !important;
}

::v-deep .el-textarea__inner {
  outline: none;
  background: #f2f3f5 !important;
}
</style>
