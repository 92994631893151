import {
    axios
} from '@/utils/request'

// 问题收集

// 获取列表数据
export function _QuestionPage(params) {
    return axios({
        url: '/Question/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _QuestionAdd(params) {
    return axios({
        url: '/Question/Add',
        method: 'post',
        data: params,
    })
}

// 删除
export function _QuestionDelete(params) {
    return axios({
        url: '/Question/Delete',
        method: 'post',
        data: params,
    })
}


// 编辑
export function _QuestionEdit(params) {
    return axios({
        url: '/Question/Edit',
        method: 'post',
        data: params,
    })
}



//详情
export function _QuestionInfo(params) {
    return axios({
        url: '/Question/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _QuestionImport(params) {
    return axios({
        url: '/Question/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _QuestionExport(params) {
    return axios({
        url: '/Question/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





