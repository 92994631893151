import {
    axios
} from '@/utils/request'

// 大中型水库后扶相关规划编审情况统计

// 获取列表数据
export function _PlanningSituationPage(params) {
    return axios({
        url: '/PlanningSituation/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _PlanningSituationAdd(params) {
    return axios({
        url: '/PlanningSituation/Add',
        method: 'post',
        data: params,
    })
}

// 编辑
export function _PlanningSituationEdit(params) {
    return axios({
        url: '/PlanningSituation/Edit',
        method: 'post',
        data: params,
    })
}


// 删除
export function _PlanningSituationDelete(params) {
    return axios({
        url: '/PlanningSituation/Delete',
        method: 'post',
        data: params,
    })
}



//详情
export function _PlanningSituationInfo(params) {
    return axios({
        url: '/PlanningSituation/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _PlanningSituationImport(params) {
    return axios({
        url: '/PlanningSituation/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _PlanningSituationExport(params) {
    return axios({
        url: '/PlanningSituation/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





