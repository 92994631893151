import {
    axios
} from '@/utils/request'

//移民群众生产生活条件变化情况

// 获取列表数据
export function _ApprovedImmigrationPage(params) {
    return axios({
        url: '/ApprovedImmigration/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _ApprovedImmigrationAdd(params) {
    return axios({
        url: '/ApprovedImmigration/Add',
        method: 'post',
        data: params,
    })
}

// 编辑
export function _ApprovedImmigrationEdit(params) {
    return axios({
        url: '/ApprovedImmigration/Edit',
        method: 'post',
        data: params,
    })
}


// 删除
export function _ApprovedImmigrationDelete(params) {
    return axios({
        url: '/ApprovedImmigration/Delete',
        method: 'post',
        data: params,
    })
}



//详情
export function _ApprovedImmigrationInfo(params) {
    return axios({
        url: '/ApprovedImmigration/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _ApprovedImmigrationImport(params) {
    return axios({
        url: '/ApprovedImmigration/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _ApprovedImmigrationExport(params) {
    return axios({
        url: '/ApprovedImmigration/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





