import {
    axios
} from '@/utils/request'

//监测评估工作人力和物力投入情况

// 获取列表数据
export function _InvestmentPage(params) {
    return axios({
        url: '/Investment/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _InvestmentAdd(params) {
    return axios({
        url: '/Investment/Add',
        method: 'post',
        data: params,
    })
}

// 编辑
export function _InvestmentEdit(params) {
    return axios({
        url: '/Investment/Edit',
        method: 'post',
        data: params,
    })
}


// 删除
export function _InvestmentDelete(params) {
    return axios({
        url: '/Investment/Delete',
        method: 'post',
        data: params,
    })
}



//详情
export function _InvestmentInfo(params) {
    return axios({
        url: '/Investment/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _InvestmentImport(params) {
    return axios({
        url: '/Investment/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _InvestmentExport(params) {
    return axios({
        url: '/Investment/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





