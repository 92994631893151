import {
    axios
} from '@/utils/request'

// 移民后期扶持资金项目清查表

// 获取列表数据
export function _ProjectInventoryPage(params) {
    return axios({
        url: '/ProjectInventory/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _ProjectInventoryAdd(params) {
    return axios({
        url: '/ProjectInventory/Add',
        method: 'post',
        data: params,
    })
}

// 删除
export function _ProjectInventoryDelete(params) {
    return axios({
        url: '/ProjectInventory/Delete',
        method: 'post',
        data: params,
    })
}


// 编辑
export function _ProjectInventoryEdit(params) {
    return axios({
        url: '/ProjectInventory/Edit',
        method: 'post',
        data: params,
    })
}



//详情
export function _ProjectInventoryInfo(params) {
    return axios({
        url: '/ProjectInventory/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _ProjectInventoryImport(params) {
    return axios({
        url: '/ProjectInventory/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _ProjectInventoryExport(params) {
    return axios({
        url: '/ProjectInventory/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





